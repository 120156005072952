/* You can add global styles to this file, and also import other style files */
@import "/src/assets/styles/table-styles.scss";
@import "/src/assets/styles/icons.scss";
@import "/src/assets/styles/theme-dark";
@import 'quill/dist/quill.snow.css';
@import "/src/assets/scss/components/news-create-edit-style/news-create-edit-style.scss";
@import "/src/assets/scss/components/article-create-edit-style/article-create-edit.scss";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.full-width {
  width: 100%;
}

.page-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}
