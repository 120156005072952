caption {
  caption-side: top;
  text-align: center;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 10px;
  text-align: left;
  color: #424242;

  @media (min-width: 1919px) {
    font-size: 15px;
  }

  @media (min-width: 3840px) {
    font-size: 30px;
    height: 30px;
  }
}

.bordered {
  border-collapse: collapse;

  th, td {
    border: 1px solid rgb(228, 228, 228) !important;
    text-align: center;

    @media (min-width: 3840px) {
      font-size: 24px;
      height: 110px;
    }
  }

  .background-red {
    background-color:  #FA8071;
  }

  .background-green {
    background-color: #90EE90;
  }

  @media (min-width: 3840px) {
    border: 2px solid rgb(228, 228, 228);
  }
}



.header-filled-custom {
  background-color: rgb(240, 240, 240) !important;

  div {
    display: flex;
    justify-content: center;
  }
}

.table {
  overflow: auto;
}
